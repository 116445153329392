<template>
  <v-row>
    <v-col cols="12" lg="6">
      <form-field-text
        v-model="obAccount.code"
        :disabled="!bEditable"
        input-type="number"
        label="account.code"
        required
      />
    </v-col>

    <v-col cols="12" lg="6">
      <form-field-text v-model="obAccount.name" required />
    </v-col>

    <v-col cols="12" lg="6">
      <payment-method-select
        v-model="obAccount.paymentmethod_id"
        :disabled="!bEditable"
        account
        label="account.type"
        required
      />
    </v-col>

    <v-col cols="12" lg="6">
      <currency-select
        v-model="obAccount.currency_id"
        :disabled="!bEditable"
        company-only
        required
      />
    </v-col>

    <v-col cols="12" lg="6">
      <currency-select
        :value="arCurrencies"
        :disabled="!bEditable"
        company-only
        label="accepted.currencies"
        multiple
        @change="onSelectCurrencies"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";
import { Account } from "@planetadeleste/vue-mc-gw";
import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import PaymentMethodSelect from "@/modules/paymentmethods/components/PaymentMethodSelect.vue";
import { CurrencyCollection } from "@planetadeleste/vue-mc-shopaholic";

@Component({
  components: { CurrencySelect, PaymentMethodSelect },
})
export default class AccountsSettings extends Vue {
  @VModel({ type: Object, default: () => new Account() })
  obAccount!: Account;

  get bEditable(): boolean {
    return this.obAccount.id ? this.obAccount.get("editable") : true;
  }

  get arCurrencies() {
    return this.obAccount.get("currencies", []);
  }

  onSelectCurrencies(obCollection: CurrencyCollection) {
    this.obAccount.set("currencies", obCollection.map("id"));
  }
}
</script>
